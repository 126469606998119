<template>
    <div
        ref="elCartList"
        class="d-flex flex-column cart-list-wrapper"
    >
        <div
            class="d-flex flex-column cart-list-inner"
        >
            <CartLoading
                v-if="cartStatus === 'unloaded' || cartStatus === 'loading'"
            ></CartLoading>
            <template
                v-else-if="cartStatus === 'loaded' || cartStatus === 'working'"
            >
                <template
                    v-if="cartObj && cartObj.lines"
                >
                    <v-row
                        v-for="(item, index) in cartObj.lines"
                        :key="item.uid"
                    >
                        <CartListItem
                            v-if="cartObj"
                            :key="item.uid"
                            :item="item"
                            :cart-obj="cartObj"
                            :cart-status="cartStatus"
                            :show-divider="cartObj && cartObj.lines && cartObj.lines.length - 1 !== index"
                            :disable-link="disableLinks"
                            :in-menu="inMenu"
                            :readonly="readonly"
                            :show-badge="showItemBadge"
                            @change-qty="changeQty($event, item.uid)"
                            @delete-line="deleteLine($event)"
                        ></CartListItem>
                    </v-row>
                </template>
                <div
                    v-if="!cartLineCount"
                    class="text-h6 text-center pt-6"
                >
                    Your cart is empty
                </div>
            </template>
            <template
                v-else-if="cartStatus === 'not-found' || (cartObj && !cartObj.lines)"
            >
                <div
                    class="text-h6 text-center pt-6"
                >
                    Your cart is empty
                </div>
            </template>
            <template
                v-else-if="cartStatus === 'error'"
            >
                <div
                    class="text-error"
                >
                    Error retrieving your cart. Please reload the page.
                </div>
            </template>
            <CartLoading
                v-else
            ></CartLoading>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useCarts, useTracking } from '~/composables';
    import CartLoading from '~/components/cart/CartLoading/CartLoading.vue';
    import CartListItem from '~/components/cart/CartListItem/CartListItem.vue';

    withDefaults(
        defineProps<{
            readonly?: boolean;
            disableLinks?: boolean;
            inMenu?: boolean;
            showItemBadge?: boolean;
        }>(),
        {
            readonly: false,
            disableLinks: false,
            inMenu: false
        }
    );

    const {
        cartStatus,
        cartObj,
        cartLineCount,
        updateLineQty,
        removeFromCart
    } = useCarts();
    const useTrackingObj = useTracking();
    const elCartList = shallowRef<HTMLElement>();

    async function changeQty(qty: number, uid: string | undefined): Promise<void> {
        if (uid) {
            await updateLineQty(uid, qty);
        }
    }

    async function deleteLine(uid: string | undefined): Promise<void> {
        if (uid) {
            const deletedItem = cartObj.value?.lines?.filter((item) => item.uid === uid);
            await removeFromCart(uid);
            useTrackingObj.track('remove_from_cart', cartObj.value, deletedItem);
        }
    }

    onBeforeMount(() => {
        useTrackingObj.track('view_cart', cartObj.value);
    });

    defineExpose({ $el: elCartList });
</script>

<style lang="scss"></style>
