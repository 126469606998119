<template>
    <div
        class="bundle-swatch-groups"
    >
        <div
            v-for="([key, groupTitle], idx) in groupTitles"
            :key="`bundler-swatch-group-${idx}`"
            :title="groupTitle"
            class="d-flex gap-1px bundler-swatch-group"
        >
            <ColorSwatch
                v-for="attr in colorAttrs.get(key)"
                :title="attr.name"
                :aria-label="attr.name"
                :color="attr.colors"
                :width="16"
                :height="16"
            ></ColorSwatch>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import ColorSwatch from '~/components/color/ColorSwatch/ColorSwatch.vue';
    import type {
        CartLineComponent,
        BundleSwatchAttrs,
        BundleSwatchTitles
    } from '~/types';
    import { useBundleComponents } from '~/composables';
    

    const props = defineProps<{
        components: CartLineComponent[]
    }>();
    
    const { getBundleSwatchData } = useBundleComponents();
    const groupTitles = ref<BundleSwatchTitles>(new Map());
    const colorAttrs = ref<BundleSwatchAttrs>(new Map());

    onBeforeMount(() => {
        const { titles, attrs } = getBundleSwatchData(props.components);
        groupTitles.value = titles;
        colorAttrs.value = attrs;
    });
</script>

<style lang="scss">
.bundle-swatch-groups {
    .bundler-swatch-group {
        border: thin solid #ccc;
        padding: 2px;
        border-radius: 6px;
    }
}
</style>
