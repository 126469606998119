<template>
    <v-img
        :src="productImageUrl"
        v-bind="$attrs"
    ></v-img>
</template>

<script lang="ts" setup>
    import { useFullImage } from '~/composables';
    import type { FullImage } from '~/types';

    const props = defineProps<{
        images: Nilish<FullImage | FullImage[]>;
    }>();
       
    const { getSingleImageUrlFromFullImage, fallbackImage } = useFullImage();

    const productImageUrl = computed<string>(() => {
        const image: Nilish<FullImage> = Array.isArray(props.images) ? props.images?.[0] : props.images;
        if (!image) return fallbackImage.value;
        return getSingleImageUrlFromFullImage(image, 'x-small') ?? fallbackImage.value;
    });
</script>

<style lang="scss"></style>
