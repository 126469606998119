import type {
    ComposableOptionsBase,
    AttributeValue,
    BundleSwatchAttrs,
    BundleSwatchTitles,
    BundleSwatchData,
    CartLineComponent
} from '~/types';
import { sortWithSortOrderAscFn } from '~/utils';

export interface UseBundleComponentsOptions extends ComposableOptionsBase {}
export function useBundleComponents(options: Partial<UseBundleComponentsOptions> = {}) {
    
    function getBundleSwatchData(
        components: CartLineComponent[]
    ): BundleSwatchData {
        const titles: BundleSwatchTitles = new Map();
        const attrs: BundleSwatchAttrs = components.reduce((acc, cmp) => {
            if (cmp.sku) {
                const {
                    productName,
                    productUid: id,
                    options: opts
                } = cmp.sku;
                const { qty } = cmp;

                const colorAttr = opts?.sort(sortWithSortOrderAscFn<AttributeValue>)
                    .find((attr) => !!attr.colors.length);

                if (colorAttr) {
                    const items = Array(qty).fill(colorAttr);
                    if (acc.has(id)) {

                        acc.get(id).push(...items);
                    } else {
                        titles.set(id!, productName!);
                        acc.set(id, items);
                    }
                }
            }

            return acc;
        }, new Map());

        return { titles, attrs };
    }

    return {
        getBundleSwatchData
    };
}
